import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import _ from "lodash";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initializeI18next } from "../methods";
import DOMPurify from "dompurify";
const REACT_APP_PG3_APP_DOMAIN = process.env.REACT_APP_PG3_APP_DOMAIN;

initializeI18next();

const customBackend = {
  type: 'backend',
  async read(language, namespace) {
    const url = `${process.env.REACT_APP_PG3_APP_DOMAIN}/language_text?lang=${language}`;
    console.log("Fetching translations from:", url);

    try {
      const response = await fetch(url);
      const data = await response.json();
      const sanitizedData = Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          key,
          typeof value === 'string' ? DOMPurify.sanitize(value) : value
        ])
      );
      return sanitizedData;
    } catch (error) {
      console.error('Error loading translations:', error);
      throw error;
    }
  }
};


i18n
  .use(customBackend)
  // .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    saveMissing: false,
    initImmediate: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    nonExplicitSupportedLngs: true,
    debug: true,
    react: {
      useSuspense: true,
    },
    interpolation: {
      format: (value, format, lng) => {
        console.log('interrrrrrrrrrrrrrr', { value });
        if (format === 'sanitizeHTML') {
          return i18n.services.formatter.format(value, 'sanitizeHTML', lng);
        }
        return value;  // Default formatting
      }
    },
    backend: {
      backends: [
        HttpBackend,
        resourcesToBackend((lng, ns) =>
          import(`../../public/locales/${lng}/${ns}.json`)
        ),
      ],
      backendOptions: [
        {
          loadPath: `${REACT_APP_PG3_APP_DOMAIN}/language_text?lang={{lng}}`,
          crossDomain: true,
        },
        {
          loadPath: "/locales/{{lng}}/translation.json",
        },
      ],
    },
  });




export default i18n;
